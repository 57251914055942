<template>
  <div class="faq-content">
    <faq-card v-for="item in cards" :key="item.Id" :data="item"></faq-card>
    <faq-card
      v-if="cards.length === 0"
      key="empity"
      :data="{
        Name: '去其他页面看看吧~',
        ConsultingDescription: '暂未发布文章',
        Time: null,
      }"
    >
    </faq-card>
    <div class="faq-card more" v-if="list.length > 0">
      <span @click="getMore">更多>></span>
    </div> 
  </div>
</template>

<script>
import FaqCard from "./FaqCard.vue";
//import { get } from "@/plugins/axios";
export default {
  components: { FaqCard },
  data() {
    return {
      list: [],
      items: [
        {
         Id:"shzs",
         ImagePath:"../assets/news/shzs.jpg",
         Name:"社会生活噪声如何处理？",
         Time:"/Date(1661356800000)/",
         ConsultingDescription: "近年来，城镇及农村居民对社会生活噪声污染的投诉大幅增加，对环境执法机构及人员的应对能力提出了新的要求。如何处理社会生活噪声污染呢？",
        },
        {
          Id: "rbxyb",
          ImagePath: "../assets/news/rbxyb.jpg",
          Name: "软包吸音板的吸音特点和吸音原理",
          Time: "/Date(1661356800000)/",
          ConsultingDescription: "软包为取代玻璃纤维和岩绵海绵等有害吸音材料而开发的新产品，纯的聚酯纤维，是接近自然而且对人体无害对环境无污染的吸音隔热材料",
        },
        {
          Id: "mzxyb",
          ImagePath: "../assets/news/mzxyb.jpg",
          Name: "木质吸音板的日常清理和运输注意事项",
          Time: "/Date(1661356800000)/",
          ConsultingDescription: "木质吸音板上面容易落灰，可以使用吸尘器除掉浮土，然后使用抹布擦干净",
        },
        {
          Id: "xyb",
          ImagePath: "../assets/news/msxy.jpg",
          Name: "吸音板在生活中的应用",
          Time: "/Date(1661356800000)/",
          ConsultingDescription: "在车间、厂房等这些开敞式的空间，由于混响声的缘由，噪声也会因而增加10-15db",
        },
        {
          Id: "byxyb",
          ImagePath: "../assets/news/byxyb.jpg",
          Name: "布艺吸音板使用范围",
          Time: "/Date(1661356800000)/",
          ConsultingDescription: "布艺吸音板使用范围",
        },
        {
          Id: "xybazff",
          ImagePath: "../assets/news/xybaz.jpg",
          Name: "吸音板安装方法",
          Time: "/Date(1661356800000)/",
          ConsultingDescription: "吸音板安装方法",
        },
      ],
      index: undefined,
      pageSize: 10,
    };
  },
  methods: {
    // async getList() {
    //   await get("/HomeApi/GetFaqListCount")
    //     .then((data) => {
    //       this.list = data;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    // async getItems(filename) {
    //   await get("/HomeApi/GetFaqByCountId", { countId: filename })
    //     .then((data) => {
    //       console.log(data);
    //       this.items.push(...data);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    getMore() {
      this.index = this.list.shift();
    },
    async init() {
    // await this.getList();
      this.getMore();
    },
  },
  computed: {
    tag() {
      return this.$route.name;
    },
    cards() {
      // if (this.tag == "faq-news")
      //   return this.items.filter((f) => f.Category === "GSXW");
      // else if (this.tag == "faq-trends")
      //   return this.items.filter((f) => f.Category === "HYDT");
      // else if (this.tag == "faq-report")
      //   return this.items.filter((f) => f.Category === "MTBD");
      return [...this.items];
    },
  },
  watch: {
    index() {
      if (this.index) this.getItems(this.index);
    },
    cards() {
      if (this.cards.length < this.pageSize && this.index) {
        this.getMore();
      }
    },
  },
  mounted() {
     this.init();
  },
};
</script>

<style lang="less" scoped>
.faq-content {
  background: rgba(245, 249, 252, 1);
  min-height: 500px;
  padding: 50px 0;
  .faq-card {
    width: 60%;
    max-width: 1440px;
    min-width: 1000px;
    margin: 0 auto;
  }
  .faq-card + .faq-card {
    margin-top: 30px;
  }

  .more {
    text-align: right;
    font-weight: 500;
    font-size: 1rem;
  }

  .more:hover {
    color: orange;
  }

  .more span {
    cursor: pointer;
  }
}
</style>
